.addressContainer {
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
}

.addressContainer input {
  border: none;
  box-shadow: inset 0 0 0 1px var(--color-border);
  height: 2rem;
  flex-grow: 1;
  padding-inline: 0.5rem;
}

.addressContainer input:focus {
  box-shadow: inset 0 0 0 1px var(--blue-400);
  outline: none;
}

.addressContainer button {
  background-color: var(--blue-400);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
}

.addressContainer button svg {
  fill: var(--color-light);
  height: 1rem;
  width: 1rem;
}

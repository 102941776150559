.company {
  font-weight: bold;
}

.description {
  font-style: italic;
}

.bold {
  font-weight: bold;
}
.sliderContainer > p {
  font-size: 0.875rem;
  text-align: center;
}

.slider {
  width: 100%;
}

.slider input {
  appearance: none;
  background-color: var(--color-gray);
  height: 0.25rem;
  opacity: 0.7;
  outline: none;
  transition: opacity 0.2s;
  width: 100%;
}

.slider input:hover {
  opacity: 1;
}

.slider input::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  background-color: var(--blue-400);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  height: 1rem;
  width: 1rem;
}

.slider input::-moz-range-thumb {
  background-color: var(--blue-400);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  height: 1rem;
  width: 1rem;
}

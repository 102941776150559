.sliderContainer > p {
  font-size: 0.875rem;
  text-align: center;
}

.slider {
  position: relative;
  width: 100%;
}

.sliderTrack {
  background-color: var(--color-gray);
  height: 0.25rem;
  margin-block: 0.5rem;
  width: 100%;
}

.slider input[type='range'] {
  appearance: none;
  -webkit-appearance: none;
  background-color: transparent;
  outline: none;
  position: absolute;
  pointer-events: none;
  top: 0;
  bottom: 0;
  width: 100%;
}

.slider input::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  background-color: var(--blue-400);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  pointer-events: auto;
  height: 1rem;
  width: 1rem;
}

.slider input::-moz-range-thumb {
  background-color: var(--blue-400);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  pointer-events: auto;
  height: 1rem;
  width: 1rem;
}

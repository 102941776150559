.filter {
  max-width: 28rem;
  margin-inline: 1rem;
  padding-block: 1rem;
  /* width: 100%; */
  width: 25%;

  /* max-height: calc(100vh - 6rem); */
  max-height: calc(100vh - 5rem);
  overflow-y: auto;
}

.filter > h1 {
  font-size: 1.75rem;
  text-align: center;
}

.sliderInputWrapper {
  border: 1px solid var(--color-border);
  border-radius: 0.5rem;
  margin-top: 1rem;
  /* padding: 1rem; */
  padding: 0 10px 5px 10px;
}

.sliderInputWrapper > h2, .filters > h2 {
  font-weight: 600;
  font-size: 1rem;

  margin: 0 0 5px 0;
}

.button {
  background-color: #0099cc;
  color: #FFFFFF;

  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;

  margin-top: 15px;
}

.filters {
  /* height: 500px; */
  height: 620px;
  overflow: hidden;
  transition: 0.3s all;
}

.collapsed {
  height: 0;
}

.checkboxList {
  display: flex;
  flex-direction: column;

  padding: 6px 12px;
  color: #555555;
  border: 1px solid #cccccc;
  border-radius: 4px;
  margin-bottom: 20px;
}

.checkboxLabel {
  display: flex;
  align-items: center;

  /* margin: 5px 0; */
  margin: 3px 0;
  font-size: 14px;
}

.checkboxLabel > input {
  margin-right: 7px;
}

.checkboxLabel > img {
  margin-right: 4px;
}

@media screen and (max-width: 768px) {
  .filter {
    width: auto;
    max-width: none;
    align-self: stretch;

    max-height: none;
  }
}
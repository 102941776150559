.getAddress {
  background-color: transparent;
  border: none;
  color: var(--blue-400);
  display: flex;
  align-items: center;
  gap: 0.25rem;
  font-size: 0.9rem;
  margin-bottom: 1rem;
  margin-top: 0.25rem;
}

.getAddress:hover {
  color: var(--blue-300);
  text-decoration: underline;
}

.getAddress svg {
  fill: var(--blue-400);
  height: 0.75rem;
  width: 0.75rem;
}

.getAddress:hover svg {
  fill: var(--blue-300);
}

.jobs {
  /* margin-inline: 1rem; */
  /* margin: 20px; */
  /* margin: 0 20px; */
  /* max-height: calc(100vh - 6rem); */
  max-height: calc(100vh - 5rem);
  /* width: 28rem; */
  width: 25%;
  overflow-y: auto;
  /* padding-block: 1rem; */
  /* padding: 20px 0; */
  padding: 20px;
}

.count {
  display: flex;
  justify-content: center;
}

.count > div {
  background-color: var(--blue-400);
  border-radius: 0.25rem;
  color: var(--color-light);
  font-weight: 600;
  padding: 0.5rem 1rem;
  margin-bottom: 0;

  display: flex;
  align-items: center;
}

.spinner {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 4px;
  fill: #FFFFFF;
  stroke: #FFFFFF;

  animation-name: spin;
  /* animation-duration: 1s; */
  /* animation-duration: 3s; */
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.job {
  display: flex;
  border-bottom: 1px solid var(--color-border);
  /* margin: 1rem; */
  /* margin: 1rem 0; */
  /* padding-block: 1rem; */
  padding-block: 20px;

  font-family: 'Helvetica Neue', Helvetica, Arial;
}

.job h2 {
  color: var(--blue-400);
  /* font-size: 1rem; */
  font-size: 18px;
  margin-block: 0.5rem;

  /* font-family: 'Helvetica Neue', Helvetica; */
  font-weight: 500;
  margin-bottom: 10px;
}

.job p {
  font-size: 0.875rem;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.imageContainer {
  /* width: 100%; */
  /* padding: 16px; */
  padding: 16px 16px 0 0;
  position: relative;
}

/* Next image overrides */
.imageContainer > span {
  position: unset !important;
  width: 50px !important;
}
.imageContainer > span img {
  object-fit: contain;
  min-width: unset !important;
  max-width: 50px !important;
  width: 100% !important;
  position: relative !important;
  height: unset !important;
}

.underline:hover {
  text-decoration: underline;
}

.black {
  /* display: flex; */
  /* align-items: center; */
  color: #000000;
}

/* .name {
  font-size: 18px;
  color: #0099cc;
} */

.icon {
  stroke: #0099cc;
  /* fill: #0099cc; */
  fill: transparent;
  stroke-width: 2rem;
  margin-right: 3px;
}

.building {
  stroke: #0099cc;
  fill: transparent;
  stroke-width: 2rem;
  margin-right: 3px;
}

.pin {
  stroke: #0099cc;
  fill: #0099cc;
  margin-left: 3px;
  margin-right: 3px;
}

@media screen and (max-width: 768px) {
  .jobs {
    width: auto;
    max-width: none;
    align-self: stretch;

    /* max-height: none; */
    /* margin-inline: 0; */
    margin: 0;
  }

  .job {
    margin: 0;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
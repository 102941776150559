.mapWrapper {
  display: flex;
  /* min-height: calc(100vh - 6rem); */
  min-height: calc(100vh - 5rem);
  flex-grow: 1;
}

.map {
  /* min-height: calc(100vh - 6rem); */
  min-height: calc(100vh - 5rem);
  width: 100%;
}

.map > div {
  /* min-height: calc(100vh - 6rem); */
  min-height: calc(100vh - 5rem);
  width: 100%;
}

@media screen and (max-width: 768px) {
  .mapWrapper {
    display: none;
  }
}
